import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
	page: {
		flexDirection: 'column',
		border: '0.06rem solid #CBD5E0',
		padding: '20px 16px',
		borderRadius: 12,
		marginTop: 20,
	},
	table: {
		display: 'flex',
		flexDirection: 'column',
		width: 'auto',
	},
	tableRow: {
		flexDirection: 'row',
	},
	tableCol: {
		width: '25%',
		borderBottom: '0.06rem solid #CBD5E0',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	firstCol: {
		borderLeft: '0.06rem solid #CBD5E0',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	headerCol: {
		borderTop: '0.06rem solid #CBD5E0',
	},
	lastCol: {
		borderRight: '0.06rem solid #CBD5E0',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	tableCell: {
		margin: 5,
		fontSize: 10,
		wordWrap: 'break-word',
	},
	nameCol: {
		width: '30%',
		paddingRight: 10,
	},
	header: {
		borderBottomColor: '#CBD5E0',
		borderTop: '0.06rem solid #CBD5E0',
		backgroundColor: 'rgba(240, 243, 247, 0.4)',
		borderBottomWidth: 0,
	},
	title: {
		color: '#000000',
		fontFamily: 'Inter',
		fontWeight: 700,
		fontSize: 12,
		textTransform: 'capitalize',
		marginBottom: 10,
	},
});

export const ClusterNodePoolsDataTable = ({ data, title }: any) => (
	<View style={styles.page} break>
		<Text style={styles.title}>{title}</Text>
		<View style={[styles.tableRow, styles.header]}>
			<View style={[styles.tableCol, styles.nameCol, styles.firstCol]}>
				<Text style={styles.tableCell}>Name</Text>
			</View>
			<View style={styles.tableCol}>
				<Text style={styles.tableCell}>Disk Size</Text>
			</View>
			<View style={styles.tableCol}>
				<Text style={styles.tableCell}>Cost</Text>
			</View>
			<View style={styles.tableCol}>
				<Text style={styles.tableCell}>Cost Per CPU</Text>
			</View>
			<View style={[styles.tableCol, styles.lastCol]}>
				<Text style={styles.tableCell}>Cost Per Memory</Text>
			</View>
		</View>
		{data && data?.length ? (
			data.map((row: any, index: any) => (
				<View style={styles.tableRow} key={index}>
					<View style={[styles.tableCol, styles.nameCol, styles.firstCol]}>
						<Text style={styles.tableCell}>{row?.name}</Text>
					</View>
					<View style={styles.tableCol}>
						<Text style={styles.tableCell}>
							{`${row?.disk_size_gb?.toFixed(3)} GB` || 0}
						</Text>
					</View>
					<View style={styles.tableCol}>
						<Text style={styles.tableCell}>
							{`$ ${row?.current_cloud_cost?.toFixed(3)}` || 0}
						</Text>
					</View>
					<View style={styles.tableCol}>
						<Text style={styles.tableCell}>
							{`$ ${row?.node_pool_cost_per_cpu?.toFixed(3)}` || 0}
						</Text>
					</View>
					<View style={[styles.tableCol, styles.lastCol]}>
						<Text style={styles.tableCell}>
							{`$ ${row?.node_pool_cost_per_memory?.toFixed(3)}` || 0}
						</Text>
					</View>
				</View>
			))
		) : (
			<Text>No data available</Text>
		)}
	</View>
);
