import AuthGuard from 'guards/AuthGuard';
import { Suspense, lazy } from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import {
	CoreRoutes,
	DashboardChildrenRoutes,
	PolicyEngineChildRoutes,
	ReportingChildRoutes,
	UserManagementPageChildRoutes,
} from './routing-paths';
import { discoveryPageConfig } from './discoveryPageRouterConfig';
import { cloudPricingRouteConfig } from './cloudPricingRouteConfig';
import GuestGuard from 'guards/GuestGuard';
import PrivacyPolicy from 'features/Privacy&Policies/PrivacyPolicies';
import TermsAndConditions from 'features/Privacy&Policies/TermsAndConditions';
import Dashboard from 'features/Admin/Dashboard';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
import ClientOverview from 'features/Admin/Clients/ClientOverview';
import UnauthorizedAccess from 'features/Auth/UnauthorisedAccess/UnauthorisedAccess';
import QueryParamGuard from 'guards/QueryParamGuard';
import SelectCloud from 'features/Clouds/SelectCloud/SelectCloud';
const CloudPricing = lazy(
	() => import('../features/CloudPricing/CloudPricing')
);
const GettingStarted = lazy(
	() => import('../features/Auth/GettingStarted/GettingStarted')
);
const Core = lazy(() => import('../features/Core/Core'));
const Authenticate = lazy(
	() => import('../features/Auth/Authenticate/Authenticate')
);
const PageNotFound = lazy(
	() => import('../features/PageNotFound/PageNotFound')
);
const Discoveries = lazy(() => import('../features/Discoveries/Discoveries'));
const ProjectSelection = lazy(
	() => import('../features/Discoveries/ProjectSelection/ProjectSelectionPage')
);
const CreateDiscoveryInfo = lazy(
	() => import('../features/Discoveries/TokenInstructions/CreateDiscoveryInfo')
);
const Permissions = lazy(
	() => import('../features/Discoveries/Permissions/Permissions')
);
const UploadTokenPage = lazy(
	() => import('../features/Discoveries/UploadToken/UploadTokenPage')
);
const UserManagement = lazy(
	() => import('../features/UserManagement/UserManagementPage')
);
const RulesAndPermissions = lazy(
	() =>
		import('../features/UserManagement/RulesAndPermissions/RulesAndPermissions')
);
const ManagementUsers = lazy(
	() => import('../features/UserManagement/Users/Users')
);
const GroupAndManagement = lazy(
	() =>
		import('../features/UserManagement/GroupsAndManagement/GroupsAndManagement')
);
const PolicyEngine = lazy(
	() => import('../features/PolicyEngine/PolicyEngine')
);

const EazyOpsPolicies = lazy(
	() =>
		import(
			'../features/PolicyEngine/EazyOpsPolicies/PoliciesTileView/PoliciesTileView'
		)
);
const ReportingPage = lazy(() => import('../features/Reporting/ReportingPage'));
const SavedReports = lazy(
	() => import('../features/Reporting/SavedReports/SavedReports')
);
const DownloadedReports = lazy(
	() => import('../features/Reporting/DownloadedReports/DownloadedReports')
);
const WhatsNew = lazy(() => import('../features/WhatsNew/WhatsNew'));
const Integrations = lazy(
	() => import('../features/Integrations/Integrations')
);
const SchedulePage = lazy(
	() => import('../features/ScheduledTasks/ScheduledTasks')
);

export const routing = createBrowserRouter([
	{
		path: CoreRoutes.SIGN_IN,
		element: (
			<GuestGuard>
				<GettingStarted mode="Sign in" />
			</GuestGuard>
		),
	},
	{
		path: CoreRoutes.SIGN_UP,
		element: (
			<GuestGuard>
				<GettingStarted mode="Sign up" />
			</GuestGuard>
		),
	},
	{
		path: CoreRoutes.VALIDATE,
		element: <Authenticate />,
	},
	{
		path: CoreRoutes.PRIVAY_POLICIES,
		element: <PrivacyPolicy />,
	},
	{
		path: CoreRoutes.TERMS_CONDITIONS,
		element: <TermsAndConditions companyName="EazyOps" websiteName="EazyOps" />,
	},
	{
		path: '/',
		element: (
			<AuthGuard>
				<QueryParamGuard paramName="grant" redirectTo="/unauthorized">
					<Core />
				</QueryParamGuard>
			</AuthGuard>
		),
		children: [
			{
				path: '/',
				element: <Navigate to="/selectCloud" replace={true} />,
			},
			{
				index: true,
				element: <Navigate to="/selectCloud" replace={true} />,
			},
			{
				path: '/selectCloud',
				element: (
					<Suspense fallback={<LoadingSpinner />}>
						<SelectCloud />
					</Suspense>
				),
			},
			{
				path: '/discoveries',
				element: (
					<Suspense fallback={<LoadingSpinner />}>
						<Discoveries />
					</Suspense>
				),
			},
			{
				path: '/unauthorized',
				element: (
					<Suspense fallback={<LoadingSpinner />}>
						<UnauthorizedAccess />
					</Suspense>
				),
			},
			{
				path: '/callback',
				element: (
					<Suspense fallback={<LoadingSpinner />}>
						<Discoveries />
					</Suspense>
				),
			},
			{
				path: '/success',
				element: (
					<Suspense fallback={<LoadingSpinner />}>
						<Discoveries />
					</Suspense>
				),
			},
			{
				path: '/selectCloud/permissions',
				element: (
					<Suspense fallback={<LoadingSpinner />}>
						<Permissions />
					</Suspense>
				),
			},
			{
				path: '/selectproject',
				element: (
					<Suspense fallback={<LoadingSpinner />}>
						<ProjectSelection />
					</Suspense>
				),
			},
			{
				path: '/selectCloud/tokeninstructions',
				element: (
					<Suspense fallback={<LoadingSpinner />}>
						<CreateDiscoveryInfo />
					</Suspense>
				),
			},
			{
				path: '/selectCloud/uploadToken',
				element: (
					<Suspense fallback={<LoadingSpinner />}>
						<UploadTokenPage />
					</Suspense>
				),
			},

			{
				path: `${CoreRoutes.DASHBOARD}`,
				element: (
					<Suspense>
						<Dashboard />
					</Suspense>
				),
			},
			{
				path: `${CoreRoutes.DASHBOARD}/${DashboardChildrenRoutes.CLIENT_OVERVIEW}/:clientID`,
				element: (
					<Suspense fallback={<LoadingSpinner />}>
						<ClientOverview />
					</Suspense>
				),
			},
			{
				path: `${CoreRoutes.WHAT_NEW}`,
				element: (
					<Suspense fallback={<LoadingSpinner />}>
						<WhatsNew />
					</Suspense>
				),
			},
			{
				path: `${CoreRoutes.INTEGRATION}`,
				element: (
					<Suspense fallback={<LoadingSpinner />}>
						<Integrations />
					</Suspense>
				),
			},
			{
				path: `${CoreRoutes.SCHEDULED_TASKS}`,
				element: (
					<Suspense fallback={<LoadingSpinner />}>
						<SchedulePage />
					</Suspense>
				),
			},

			discoveryPageConfig,
			{
				path: `${CoreRoutes.USER}`,
				element: (
					<Suspense fallback={<LoadingSpinner />}>
						<UserManagement />
					</Suspense>
				),
				children: [
					{
						path: UserManagementPageChildRoutes.ROLES_AND_PERMISSIONS,
						element: (
							<Suspense fallback={<LoadingSpinner />}>
								<RulesAndPermissions />
							</Suspense>
						),
					},
					{
						path: UserManagementPageChildRoutes.USERS,
						element: (
							<Suspense fallback={<LoadingSpinner />}>
								<ManagementUsers />
							</Suspense>
						),
					},
					{
						path: UserManagementPageChildRoutes.GROUP_MANAGEMENT,
						element: (
							<Suspense fallback={<LoadingSpinner />}>
								<GroupAndManagement />
							</Suspense>
						),
					},
				],
			},
			{
				path: `${CoreRoutes.POLICY_ENGINE}`,
				element: (
					<Suspense fallback={<LoadingSpinner />}>
						<PolicyEngine />
					</Suspense>
				),
				children: [
					{
						index: true,
						element: (
							<Navigate
								to={PolicyEngineChildRoutes.EAZYOPS_POLICIES}
								replace={true}
							/>
						),
					},
					{
						path: PolicyEngineChildRoutes.EAZYOPS_POLICIES,
						element: (
							<Suspense fallback={<LoadingSpinner />}>
								<EazyOpsPolicies />
							</Suspense>
						),
					},
				],
			},
			{
				path: `${CoreRoutes.REPORTING}`,
				element: (
					<Suspense fallback={<LoadingSpinner />}>
						<ReportingPage />
					</Suspense>
				),
				children: [
					{
						index: true,
						element: (
							<Navigate to={ReportingChildRoutes.SAVED} replace={true} />
						),
					},
					{
						path: ReportingChildRoutes.SAVED,
						element: (
							<Suspense fallback={<LoadingSpinner />}>
								<SavedReports />
							</Suspense>
						),
					},
					{
						path: ReportingChildRoutes.DOWNLOADED,
						element: (
							<Suspense fallback={<LoadingSpinner />}>
								<DownloadedReports />
							</Suspense>
						),
					},
				],
			},
			{
				path: `${CoreRoutes.CLOUD_PRICING}`,
				element: (
					<Suspense fallback={<LoadingSpinner />}>
						<CloudPricing />
					</Suspense>
				),
			},

			cloudPricingRouteConfig,
		],
	},
	{
		path: '*',
		element: <PageNotFound />,
	},
]);
