import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import LogoIcon from 'assets/reportAssets/Logo.png';
import { IUser } from 'common/interfaces';

const styles = StyleSheet.create({
	page: {
		fontFamily: 'Inter',
		fontSize: 11,
		paddingLeft: 0,
		lineHeight: 1.5,
		flexDirection: 'row',
		paddingBottom: 10,
		borderBottom: ' 0.06rem solid #CBD5E0',
		justifyContent: 'space-between',
	},
	left: {
		fontFamily: 'Inter',
		paddingLeft: 0,
		lineHeight: 1.5,
		flexDirection: 'row',
		gap: 20,
	},
	right: {
		fontFamily: 'Inter',
		fontSize: 11,
		paddingRight: 0,
		lineHeight: 1.5,
		flexDirection: 'row',
		justifyContent: 'space-between',
		gap: 5,
	},
	logo: {
		objectFit: 'contain',
		width: 50,
		height: 21,
	},
	border: {
		borderRight: '0.35px solid #000000',
	},
	subText: {
		color: '#000000',
		fontSize: '7px',
		fontWeight: 700,
	},
	text: {
		color: '#000000',
		fontSize: '7px',
		fontWeight: 400,
	},
	subReportText: {
		fontSize: '6px',
		color: '#949494',
	},
	reportText: {
		fontSize: '6px',
		color: '#000000',
	},
});

const Header = ({ user }: { user: IUser | null }) => {
	const currentDate = new Date();
	const formattedDate = currentDate.toISOString().slice(0, 10);
	return (
		<View fixed style={styles.page}>
			<View style={styles.left}>
				<Image style={styles.logo} src={LogoIcon} />
				<View style={styles.border} />
				<View>
					<Text style={styles.text}>Cut cloud costs with EazyOps:</Text>
					<Text style={styles.subText}>Simplicity, Savings.</Text>
				</View>
			</View>
			<View>
				<View style={styles.right}>
					<Text style={styles.reportText}>Report Date:</Text>
					<Text style={styles.subReportText}>{formattedDate}</Text>
				</View>
				<View style={styles.right}>
					<Text style={styles.reportText}>Generated By:</Text>
					<Text style={styles.subReportText}>{user?.full_name}</Text>
				</View>
			</View>
		</View>
	);
};
export default Header;
