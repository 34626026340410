import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { IReportsData } from 'common/interfaces';

const styles = StyleSheet.create({
	container: {
		flexDirection: 'column',
		gap: 10,
	},
	mainContainer: {
		border: '0.06rem solid #CBD5E0',
		padding: '20px 16px',
		borderRadius: 12,
		marginTop: 20,
		flexDirection: 'column',
		gap: 10,
	},
	title: {
		color: '#000000',
		fontFamily: 'Inter',
		fontWeight: 700,
		fontSize: 12,
		textTransform: 'capitalize',
	},
	optimisedSavingContailer: {
		backgroundColor: 'rgba(194, 245, 219, 0.5)',
		border: '1px solid #A0EBC7',
		borderRadius: 6,
		flexDirection: 'row',
		justifyContent: 'space-between',
		height: 70,
		padding: '12px 18px',
	},
	unoptimisedSavingContailer: {
		backgroundColor: 'rgba(227, 236, 255, 0.3)',
		border: '1px solid #D9E5FF',
		borderRadius: 6,
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: '12px 18px',
		height: 70,
	},
	dataContainer: {
		flexDirection: 'column',
		gap: '5px',
	},
	dataTitle: {
		color: '#000000',
		textTransform: 'uppercase',
		fontSize: 8,
		fontWeight: 300,
	},
	datasubTitle: { color: '#000000', fontSize: 24, fontWeight: 700 },
	avgContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		gap: '20px',
		paddingTop: '20px',
	},
	avgContainerItem: {
		flex: '33.33',
		border: '0.06rem solid #CBD5E0',
		padding: '20px 16px',
		borderRadius: 12,
	},
});

const ResourceActions = ({ data }: { data: IReportsData }) => {
	return (
		<View style={styles.container}>
			<View style={styles.mainContainer}>
				<View>
					<Text style={styles.title}>Actions</Text>
					<View style={styles.unoptimisedSavingContailer}>
						{Object.entries(data.action).map(([key, value]) => {
							return (
								<View style={styles.dataContainer} key={key}>
									<Text style={styles.dataTitle}>{key}</Text>
									<Text style={styles.datasubTitle}>{value}</Text>
								</View>
							);
						})}
					</View>
				</View>
			</View>
			{/* <View style={styles.avgContainer}>
				<View style={styles.avgContainerItem}>
					<Text style={styles.title}>AVG. PROVISIONED</Text>
					<Text>{data.average_provisioned ?? 'NA'}</Text>
				</View>
				<View style={styles.avgContainerItem}>
					<Text style={styles.title}>AVG. REQUESTED</Text>
					<Text>{data.average_requested ?? 'NA'}</Text>
				</View>
				<View style={styles.avgContainerItem}>
					<Text style={styles.title}>AVG. USED</Text>
					<Text>{data.average_used ?? 'NA'}</Text>
				</View>
			</View> */}
		</View>
	);
};

export default ResourceActions;
