import { ReactElement, useMemo, memo } from 'react';
import './PageHeader.scss';
import { ReactComponent as AddIcons } from 'assets/svgs/plus.svg';
import { classNames, getLocalFormattedTime } from 'common/helpers';
import { Button } from 'components/Button/Button';
import { CustomizedTooltip } from 'components/Tooltip/Tooltip';
import { SyncOutlined } from '@mui/icons-material';
import useCloudDetail from 'hooks/useCloudDetail';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { IDiscoveryOverview, IDiscoveryProject } from 'common/interfaces';
import {
	AccountChildrenRoutes,
	CoreRoutes,
	DiscoveryChildrenRoutes,
} from 'routing/routing-paths';
import ClickableTextSearch from 'components/ProjectSearchInput/ProjectSearchInput';

interface PageHeaderProps {
	title?: string;
	leftControls?: ReactElement;
	rightControls?: ReactElement;
	bottomControls?: ReactElement;
	tooltipContent?: ReactElement;
	loading?: boolean;
	label?: string;
	btnLabel?: string;
	onBtnClick?: () => void;
	lastSync?: string | null;
	showProjects?: boolean;
}

const PageHeader = memo(
	({
		title = '',
		leftControls,
		rightControls,
		bottomControls,
		loading = false,
		label,
		btnLabel,
		onBtnClick,
		tooltipContent,
		lastSync,
		showProjects = true,
	}: PageHeaderProps) => {
		const { icon: CloudIcon } = useCloudDetail();
		const { discovery } = useOutletContext<{
			account: IDiscoveryProject;
			discovery: IDiscoveryOverview;
		}>();
		const navigate = useNavigate();

		const projects = useMemo(() => {
			return discovery?.projects?.map((proj) => {
				return { label: proj?.name, value: proj?.id };
			});
		}, [discovery]);

		const projectChangeHandler = (account) => {
			navigate(
				`${CoreRoutes.DISCOVERIES}${CoreRoutes.DISCOVERY_PAGE}/${discovery?.id}/${DiscoveryChildrenRoutes.ACCOUNT_OVERVIEW}/${account.value}/${AccountChildrenRoutes.OVERVIEW}`
			);
		};
		return (
			<div
				data-cyid="cy-page-header-component"
				className="page-header-component"
			>
				<div
					className={`page-header-component-top ${
						!!btnLabel ? 'with-btn' : ''
					}`}
				>
					<div className="page-header-component-top__head">
						<h1 className={classNames(loading && 'loading')}>
							{CloudIcon && <CloudIcon />}
							{!showProjects && title}
							{showProjects && (
								<ClickableTextSearch
									label={title}
									projects={projects}
									onProjectSelect={projectChangeHandler}
									tooltipContent={tooltipContent}
								/>
							)}
							{!showProjects && (
								<span className="page-header-label">
									{!!tooltipContent && (
										<CustomizedTooltip
											tooltipContent={tooltipContent}
											placement="bottom-end"
											data_cyid="cy-tooltip-page-header-label"
										/>
									)}
								</span>
							)}
						</h1>
						{lastSync && (
							<span className="sync" title="last synced">
								{getLocalFormattedTime(lastSync)}
								<SyncOutlined
									sx={{ height: '15px' }}
									titleAccess="last synced"
								/>
							</span>
						)}
					</div>

					{(!!leftControls || !!rightControls) && (
						<div
							data-cyid="cy-page-header-component-controls"
							className={classNames(
								'page-header-component-controls',
								(!leftControls || !rightControls) && 'single'
							)}
						>
							{leftControls && (
								<div className="page-header-component-controls-left">
									{leftControls}
								</div>
							)}
							{rightControls && (
								<div className="page-header-component-controls-right">
									{rightControls}
								</div>
							)}
						</div>
					)}
					{btnLabel && (
						<>
							<Button
								className="blue"
								icon={<AddIcons />}
								text={btnLabel}
								small
								onClick={onBtnClick}
								data_cyid="cy-page-header-add-button"
							/>
						</>
					)}
				</div>
				{bottomControls}
			</div>
		);
	}
);

export { PageHeader };
