import { useState } from 'react';
import { IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectListOfIntegrations } from 'store/integrations/integration-selector';
import { ReactComponent as Jira } from 'assets/svgs/jira.svg';
import CreateTicket from 'components/CreateTickets/CreateTicket';
import {
	IDBResource,
	IGKEResource,
	IIPs,
	IRDSSnapshots,
	ISnapshots,
	IVMResource,
	IVolumeResource,
} from 'common/interfaces';

interface ICreateJiraTicketButtonProps {
	resource:
		| IVMResource
		| IDBResource
		| IVolumeResource
		| IGKEResource
		| IIPs
		| ISnapshots
		| IRDSSnapshots;
	action: string;
	resourceType:
		| 'compute'
		| 'disk'
		| 'database'
		| 'disk_snapshots'
		| 'database'
		| 'database_snapshots'
		| 'ips'
		| 'cluster';
}

const CreateJiraTicketButton = ({
	resourceType,
	resource,
	action,
}: ICreateJiraTicketButtonProps) => {
	const [isTicketOpen, setTicketOpen] = useState(false);
	const [integrationId, setIntegrationId] = useState<string>('');
	const available_integrations = useSelector(
		selectListOfIntegrations
	).integrations;

	const handleClick = () => {
		const id = available_integrations?.find(
			(integration) => integration.type.toLowerCase() === 'jira'
		).id;
		setIntegrationId(id);
		setTicketOpen(true);
	};

	return (
		<>
			{available_integrations?.find(
				(integration) => integration.type.toLowerCase() === 'jira'
			) ? (
				<IconButton
					className="hollow-green-button"
					onClick={() => handleClick()}
					title="Create Jira Ticket"
				>
					<Jira height="30px" width="30px" />
				</IconButton>
			) : null}
			{isTicketOpen && (
				<CreateTicket
					open={isTicketOpen}
					onClose={() => setTicketOpen(false)}
					resourceType={resourceType}
					integration_id={integrationId}
					resource={resource}
					action={action}
				/>
			)}
		</>
	);
};

export default CreateJiraTicketButton;
