/* eslint-disable quotes */
import { Button } from '@mui/material';
import { AutoAwesomeOutlined } from '@mui/icons-material';
import { styled } from '@mui/system';
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
	IDBResource,
	IGKEResource,
	IIPs,
	IRDSSnapshots,
	ISnapshots,
	IVMResource,
	IVolumeResource,
} from 'common/interfaces';
import { getHighestResourceSaving } from 'common/helpers';
import { getSummary } from 'store/integrations/integration-thunk';
import { Dispatch, SetStateAction } from 'react';
import { useNotifications } from 'components/Notifications/useNotifications';
import { NotificationSeverity } from 'components/Notifications/Notification/Notification';

const SparkleButton = styled(Button)(({ theme }) => ({
	position: 'relative',
	padding: '10px',
	fontSize: '14px',
	fontWeight: 'bold',
	textTransform: 'capitalize',
	borderRadius: '8px',
	background: 'linear-gradient(45deg, #6a4cfc, #00c6ff, #6a4cfc)',
	backgroundSize: '400% 400%',
	color: 'white',
	boxShadow: '0 4px 15px rgba(105, 99, 255, 0.4)',
	border: 'none',
	outline: 'none',
	transition: 'all 0.4s ease',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	overflow: 'hidden',

	'&-text': {
		textTransform: 'capitalize',
	},
	'&-startIcon': {
		paddingBottom: '3px',
	},
	'&:hover': {
		backgroundPosition: '100% 100%',
		boxShadow: '0 4px 20px rgba(105, 99, 255, 0.6)',
	},

	'& svg': {
		marginRight: '8px',
		fontSize: '22px',
	},

	'&:before': {
		content: "''",
		position: 'absolute',
		top: '-150%',
		left: '-150%',
		width: '300%',
		height: '300%',
		background:
			'radial-gradient(circle, rgba(0, 255, 255, 0.4) 20%, rgba(0, 255, 255, 0) 60%)',
		animation: 'sparkle-animation 1.5s forwards',
		pointerEvents: 'none',
	},

	'@keyframes sparkle-animation': {
		'0%': {
			transform: 'translate(50%, 50%) scale(1)',
			opacity: 0.8,
		},
		'50%': {
			transform: 'translate(0%, 0%) scale(1.1)',
			opacity: 1,
		},
		'100%': {
			transform: 'translate(50%, 50%) scale(1)',
			opacity: 0.8,
		},
	},
}));

// Main component
interface IAIButtonProps {
	resource:
		| IVMResource
		| IDBResource
		| IVolumeResource
		| IGKEResource
		| IIPs
		| IRDSSnapshots
		| ISnapshots;
	action: string;
	resourceType: string;
	setSummary: Dispatch<SetStateAction<string>>;
	setDescription: Dispatch<SetStateAction<string>>;
	setTicketLabel: Dispatch<SetStateAction<{ label: string; value: string }[]>>;
	isDisabled: boolean;
}
export default function AIButton({
	resource,
	action,
	resourceType,
	setDescription,
	setSummary,
	setTicketLabel,
	isDisabled,
}: IAIButtonProps) {
	const dispatch = useAppDispatch();
	const notification = useNotifications();

	const handleClick = () => {
		const formatted_resource: {
			resource_name: string;
			resource_type: string;
			action: string;
			project_id: string;
			date?: string;
			current_monthly_cost?: number;
			recommended_monthly_cost?: number;
			savings_amount?: number;
			savings_percentage?: number;
			cpu_utilization?: string;
			memory_utilization?: string;
			current_machine_type?: string;
			recommended_machine_type?: string;
			project_name?: string;
			days_unattached_for?: number;
		} = {
			resource_name: resource.name,
			resource_type: resourceType,
			project_id: (
				resource as IVMResource | IDBResource | IVolumeResource | IGKEResource
			)?.project?.name,
			project_name: (
				resource as IVMResource | IDBResource | IVolumeResource | IGKEResource
			)?.project?.name,
			action,
			current_monthly_cost: (
				resource as IVMResource | IDBResource | IVolumeResource | IGKEResource
			).normalizedObject.current_cloud_cost,
			recommended_monthly_cost: getHighestResourceSaving(
				(resource as IVMResource | IDBResource | IVolumeResource | IGKEResource)
					.savings[action]
			)?.detail.cost_after,
			savings_amount:
				(resource as IVMResource | IDBResource | IVolumeResource | IGKEResource)
					?.max_savings || 0,
			savings_percentage: (
				resource as IVMResource | IDBResource | IVolumeResource | IGKEResource
			).saving_percentage,
			cpu_utilization:
				(
					resource as IVMResource | IDBResource | IVolumeResource | IGKEResource
				)?.normalizedObject?.cpu_utilization?.toString() || '',
			memory_utilization:
				(
					resource as IVMResource | IDBResource | IVolumeResource | IGKEResource
				)?.normalizedObject?.memory_utilization?.toString() || '',
			current_machine_type: getHighestResourceSaving(
				(resource as IVMResource | IDBResource | IVolumeResource | IGKEResource)
					.savings[action]
			)?.detail.type_before,
			recommended_machine_type: getHighestResourceSaving(
				(resource as IVMResource | IDBResource | IVolumeResource | IGKEResource)
					.savings[action]
			)?.detail.type_after,
			days_unattached_for: getHighestResourceSaving(
				(resource as IVMResource | IDBResource | IVolumeResource | IGKEResource)
					.savings[action]
			)?.detail.last_vm_attached,
		};

		dispatch(getSummary(formatted_resource))
			.unwrap()
			.then((res) => {
				setSummary(res.summary);
				setDescription(res.description);
				setTicketLabel([
					{ label: 'Urgent', value: 'Urgent' },
					{ label: 'High Priority', value: 'High Priority' },
				]);
			})
			.catch((err) => {
				notification.open(
					'Something went wrong, please try again later',
					NotificationSeverity.ERROR
				);
			});
	};
	return (
		<SparkleButton
			variant="contained"
			startIcon={<AutoAwesomeOutlined />}
			onClick={(ev) => {
				ev.preventDefault();
				handleClick();
			}}
			disabled={isDisabled}
		>
			AI Fill
		</SparkleButton>
	);
}
