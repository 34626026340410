import { useEffect, useMemo, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { formatDate, getMonthsOld } from 'common/helpers';
import { IDiscoveryOverview, IDiscoveryProject, IIPs } from 'common/interfaces';
import { ITableColumn, Table } from 'components/Table/Table';
import { Tile } from 'components/Tile/Tile';
import { useNotifications } from 'components/Notifications/useNotifications';
import { ResourcesService } from 'api/services/resources.service';
import { NotificationSeverity } from 'components/Notifications/Notification/Notification';
import { PageHeader } from 'components/PageHeader/PageHeader';
import '../AccountResourcesList.scss';
import { DynamicTabs } from 'components/Tabs/Tabs';
import { useDispatch } from 'react-redux';
import { setBreadCrumbsData } from 'store/discovery/discovery';
import { AccountChildrenRoutes, CoreRoutes } from 'routing/routing-paths';
import { ApplyProjections } from 'components/ApplyProjections/ApplyProjections';
import useDiscoveryTooltipContent from 'hooks/useDiscoveryTooltipContent';
import { useSelector } from 'react-redux';
import { selectSessionData } from 'store/discovery/discovery-selectors';
import useCloudDetail from 'hooks/useCloudDetail';
import { selectListOfIntegrations } from 'store/integrations/integration-selector';
import { IconButton } from '@mui/material';
import { ReactComponent as Jira } from 'assets/svgs/jira.svg';
import CreateTicket from 'components/CreateTickets/CreateTicket';
import CreateJiraTicketButton from 'components/CreateJiraTicketButton/CreateJiraTicketButton';

const session = sessionStorage.getItem('session');
const session_data = session ? JSON.parse(session) : null;

const ResourceUnusedIPs = () => {
	const [page, setPage] = useState(0);
	const [unused_ips, setIPsData] = useState<IIPs[] | null>(null);
	const [selectedTab, setSelectedTab] = useState<number>(0);

	const notification = useNotifications();
	const { id } = useCloudDetail();
	const {
		account,
		discovery,
	}: { account: IDiscoveryProject; discovery: IDiscoveryOverview } =
		useOutletContext();

	const onPageChange = (page: number) => setPage(page);
	const ipsColumn: ITableColumn<IIPs>[] = [
		{
			name: 'Name',
			element: (resource: IIPs) => (
				<div className="logs-resource-table-cell-name resource-table-cell-name">
					{resource.name}
				</div>
			),
			minWidth: '200px',
			sortable: true,
			sortKey: 'name',
		},
		{
			name: 'Address',
			element: (resource: IIPs) => (
				<div className="logs-resource-table-cell-type">{resource?.address}</div>
			),
			minWidth: '100px',
			className: 'blue',
		},
		{
			name: 'Cost',
			element: (resource: IIPs) => (
				<div className="logs-resource-table-cell-type blue">
					$ {resource?.cost?.toFixed(3) || 0}{' '}
				</div>
			),
			minWidth: '100px',
			className: 'blue',
			sortable: true,
			sortKey: 'cost',
		},
		{
			name: 'IPs Age',
			element: (resource: IIPs) => (
				<div className="logs-resource-table-cell-type">{resource.age}</div>
			),
			minWidth: '100px',
			sortable: true,
			sortKey: 'created_at',
		},
		{
			name: 'Created On',
			element: (resource: IIPs) => (
				<div className="logs-resource-table-cell-type">
					{formatDate(resource.created_at)}
				</div>
			),
			minWidth: '100px',
			sortable: true,
			sortKey: 'created_at',
		},
		{
			name: 'EazyHub',
			element: (resource: IIPs) => (
				<div className="resource-table-cell-actions">
					<CreateJiraTicketButton
						resource={resource}
						resourceType="ips"
						action="delete"
					/>
				</div>
			),
			minWidth: '100px',
		},
	];

	useEffect(() => {
		setIPsData(null);
		if (account) {
			const storage_connector_id = localStorage.getItem('connector_id');
			const connector_id = storage_connector_id
				? JSON.parse(storage_connector_id)[id]
				: undefined;

			setIPsData(null);
			ResourcesService.getSnapshots(account?.name, 'ip', connector_id as string)
				.then(({ data }) => {
					setIPsData(
						data?.map((item: any) => {
							return {
								id: String(item?.id),
								age: getMonthsOld(item.created_at),
								...item,
							};
						})
					);
				})
				.catch((error) => {
					setIPsData([]);
					notification.open(
						'Something went wrong while fetching IPs',
						NotificationSeverity.ERROR
					);
					console.log({ error });
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const IPsCostSum = useMemo(() => {
		return unused_ips?.reduce((sum, disk) => sum + disk.cost, 0);
	}, [unused_ips]);

	const { getTooltipContent } = useDiscoveryTooltipContent({
		account,
		discovery,
	});

	const dispatch = useDispatch();
	const session_connector = useSelector(selectSessionData);
	const isSingleAccount =
		session_data &&
		session_connector[session_data?.tenant_id as string]?.isSingleAccount;

	useEffect(() => {
		dispatch(
			setBreadCrumbsData([
				...(!isSingleAccount
					? [
							{
								to: `${CoreRoutes.DISCOVERIES}${CoreRoutes.DISCOVERY_PAGE}/${discovery?.id}`,
								label: 'Accounts',
							},
					  ]
					: []),
				{
					to: `${CoreRoutes.DISCOVERIES}${CoreRoutes.DISCOVERY_PAGE}/${discovery?.id}/account/${account?.id}/${AccountChildrenRoutes.OVERVIEW}`,
					label: `${account?.name}`,
				},
				{
					to: '',
					label: 'Unused IPs',
				},
			])
		);
	}, [account?.id, account?.name, discovery?.id, dispatch, isSingleAccount]);

	return (
		<div className="snapshots-component">
			<PageHeader
				title={account?.name}
				label="Account"
				lastSync={account?.last_synced_at}
				tooltipContent={getTooltipContent()}
			/>
			<ApplyProjections
				savingPercentage={account?.savings_percentage}
				potentialCost={account?.potential_cost}
				actualCost={account?.actual_cost}
				className="account-overview-component-apply-projections"
				showSavingSelection={false}
				isAccountsOverview={false}
				account={account}
				discovery={discovery}
				data_cyid="cy-account-overview-projections"
			/>
			<DynamicTabs
				selectedTab={selectedTab}
				setSelectedTab={setSelectedTab}
				tabs={[
					{
						label: 'Computes',
						content: (
							<div className="snapshots-component__content">
								{unused_ips?.length ? (
									<div className="snapshots-component__tiles">
										<Tile
											text={`Our analysis shows you've spent $${IPsCostSum?.toFixed(
												3
											)} on unused IPs. These can be removed to avoid ongoing costs of approximately $${
												7.3 * unused_ips.length
											} per month.`}
											color="green"
										/>
										<span>
											<strong>Total: </strong>
											{unused_ips.length}
										</span>
									</div>
								) : null}

								<Table
									rows={unused_ips || []}
									columns={ipsColumn}
									totalCount={unused_ips?.length || 0}
									placeholderText="We couldn’t find any IPs"
									isSkeleton={!unused_ips}
									className="account-resources-list"
									data_cyid="cy-snapshots-list-table"
									onPageChange={onPageChange}
									activePage={page}
									itemsPerPage={15}
								/>
							</div>
						),
					},
				]}
			/>
		</div>
	);
};

export default ResourceUnusedIPs;
