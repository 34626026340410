import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { labelMapping } from 'common/helpers';
import { IImplementationComplexity } from 'common/interfaces';
import Cluster from 'assets/reportAssets/Cluster.png';
import Volumes from 'assets/reportAssets/Volume.png';
import Computes from 'assets/reportAssets/Compute.png';
import Database from 'assets/reportAssets/Databases.png';
import Logs from 'assets/reportAssets/Logs.png';
import IPs from 'assets/reportAssets/ipAddress.png';

// Create styles for the PDF
const styles = StyleSheet.create({
	page: {
		marginTop: 20,
		// flexDirection: 'column',
	},
	title: {
		color: '#000000',
		fontFamily: 'Inter',
		fontWeight: 700,
		fontSize: 24,
	},
	table: {
		display: 'flex',
		flexDirection: 'column',
		border: '0.06rem solid #CBD5E0',
		borderRadius: 6,
		marginBottom: 10,
	},
	tableHeader: {
		flexDirection: 'row',
		borderBottom: '0.06rem solid #CBD5E0',
		backgroundColor: '#f0f0f0',
		padding: 5,
	},
	tableRow: {
		borderBottom: '0.06rem solid #CBD5E0',
		flexDirection: 'row',
		padding: 5,
	},
	tableCell: {
		flex: 1,
		padding: '10px 10px',
		color: '#000000',
		fontFamily: 'Inter',
		fontWeight: 400,
		fontSize: 10,
	},
	headerText: {
		fontWeight: 'bold',
	},
	flexRow: {
		padding: '10px 10px',
		flexDirection: 'row',
		gap: '5px',
		alignItems: 'center',
		width: '280px',
	},
	resourceIcon: { width: '13.75px', height: '10.75px', objectFit: 'contain' },
});

const iconMapping: Record<string, string> = {
	clusters: Cluster,
	instances: Computes,
	disks: Volumes,
	databases: Database,
	logs: Logs,
	disk_snapshots: Volumes,
	database_snapshots: Database,
	static_ips: IPs,
};
const ImplementationComplexity = ({
	data,
}: {
	data: IImplementationComplexity;
}) => {
	return (
		<View style={styles.page}>
			<Text style={styles.title}>Implementation Complexity</Text>
			<View style={styles.table}>
				{/* Header Row */}
				<View style={styles.tableHeader}>
					<Text style={[styles.tableCell, styles.headerText]}>Category</Text>
					<Text style={[styles.tableCell, styles.headerText]}>Complexity</Text>
				</View>
				{/* Data Rows */}
				{Object.entries(data).map(([category, complexity], index) => (
					<View key={index} style={styles.tableRow}>
						{/* <Text style={styles.tableCell}>{labelMapping[category]}</Text> */}
						<View style={{ ...styles.flexRow }}>
							<Image style={styles.resourceIcon} src={iconMapping[category]} />
							<Text style={{ ...styles.tableCell, padding: 0 }}>
								{labelMapping[category]}
							</Text>
						</View>
						<Text style={styles.tableCell}>{complexity}</Text>
					</View>
				))}
			</View>
		</View>
	);
};

export default ImplementationComplexity;
