import { ArrowBack } from '@mui/icons-material';
import { Box, Button, Typography, Container, IconButton } from '@mui/material';
import Logo from 'assets/svgs/logo.svg';
import { useNavigate } from 'react-router-dom';

const StickyHeader = () => {
	const navigate = useNavigate();
	return (
		<Box
			mb={2}
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			padding={'20px'}
			bgcolor="#FAFAFA"
			position="sticky"
			zIndex={2}
			top={0}
			borderBottom={'0.06rem solid #CBD5E0'}
		>
			<IconButton
				onClick={() => {
					navigate(-1);
				}}
			>
				<ArrowBack />
			</IconButton>
			<Container className="header-content-container">
				<Box>
					<img src={Logo} alt="Company Logo" height={70} width={150} />
				</Box>

				<Button
					variant="contained"
					color="secondary"
					href="https://www.eazyops.com/"
					target="_blank"
					className="visit-website-button"
				>
					<Typography variant="button">Visit Website</Typography>
				</Button>
			</Container>
		</Box>
	);
};

export default StickyHeader;
