import { Text, View, StyleSheet } from '@react-pdf/renderer';
import ReportItemsTable from './ReportItemsTable';
import { IReportsData } from 'common/interfaces';

const styles = StyleSheet.create({
	mainContainer: {
		marginTop: 20,
		flexDirection: 'column',
		gap: 10,
	},
	title: {
		color: '#000000',
		fontFamily: 'Inter',
		fontWeight: 700,
		fontSize: 24,
	},
	subtitle: {
		color: '#000000',
		fontFamily: 'Inter',
		fontWeight: 400,
		fontSize: 10,
	},
	summaryTableContainer: {
		backgroundColor: 'rgba(240, 243, 247, 0.4)',
		border: '0.06rem solid #CBD5E0',
		borderRadius: 6,
		flexDirection: 'row',
		marginTop: 10,
	},
	dataContainer: {
		flexDirection: 'column',
		gap: '5px',
	},
	dataTitle: {
		color: '#000000',
		textTransform: 'uppercase',
		fontSize: 8,
		fontWeight: 300,
	},
	dataSubTitle: { color: '#000000', fontSize: 24, fontWeight: 700 },
	dataSubTitleSubText: { color: '#626262', fontSize: 16, fontWeight: 400 },
});

const ReportingSummaryTable = ({ data }: { data: IReportsData }) => (
	<View style={styles.mainContainer}>
		<View>
			<Text style={styles.title}>Resources Summary</Text>
			<Text style={styles.subtitle}>
				This report will summarize the anticipated cost-saving initiatives and
				optimizations for each resource type.
			</Text>
			<View style={styles.summaryTableContainer}>
				<ReportItemsTable data={data} />
			</View>
		</View>
	</View>
);

export default ReportingSummaryTable;
