import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IIntegrations } from 'common/interfaces';
import { getIntegrations, getSummary } from './integration-thunk';


interface IntegrationState {
    integrations: IIntegrations[];
    ticket_summary: any;
    loading: boolean
}

const initialState: IntegrationState = {
    integrations: [],
    loading: false,
    ticket_summary: null
};
const integrationSlice = createSlice({
    name: 'integration',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getIntegrations.pending, (state) => {
                state.loading = true;
                state.integrations = [];
            })
            .addCase(getIntegrations.fulfilled, (state, action: PayloadAction<IIntegrations[]>) => {
                state.integrations = action.payload;
                state.loading = false;
            })
            .addCase(getIntegrations.rejected, (state, action) => {
                state.loading = false;
                state.integrations = [];
            })
            .addCase(getSummary.pending, (state) => {
                state.loading = true;
            }
            ).addCase(getSummary.fulfilled, (state, action) => {
                state.ticket_summary = action.payload
                state.loading = false;
            }
            ).addCase(getSummary.rejected, (state) => {
                state.loading = false;
            }
            );
    },
});

export const integrationReducer = integrationSlice.reducer;
