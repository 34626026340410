import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { useMemo } from 'react';

const styles = StyleSheet.create({
	main: {
		flexDirection: 'column',
		border: '0.06rem solid #CBD5E0',
		padding: '20px 16px',
		borderRadius: 12,
		marginTop: 20,
	},
	container: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	leftColumn: {
		marginRight: 10,
	},
	mainTitle: {
		color: '#000000',
		fontFamily: 'Inter',
		fontWeight: 700,
		fontSize: 12,
		textTransform: 'capitalize',
	},
	heading: {
		flexDirection: 'row',
		alignItems: 'center',
		gap: 5,
	},
	filled: {
		height: 9,
		width: 9,
		backgroundColor: '#FBBC05',
		border: '1px solid #FBBC05',
		borderRadius: '50%',
	},
	dashed: {
		height: 9,
		width: 9,
		borderColor: 'black',
		borderStyle: 'dashed',
		borderWidth: 1,
		borderRadius: '50%',
	},
	hollow: {
		height: 9,
		width: 9,
		borderColor: 'black',
		borderWidth: 1,
		borderRadius: '50%',
	},
	title: {
		fontWeight: 'bold',
		fontSize: 10,
	},
	rightColumn: {
		marginRight: 10,
		textAlign: 'right',
	},
	border: {
		borderLeftWidth: 1,
		borderColor: 'black',
		height: '100%',
		marginRight: 10,
	},
	unit: {
		fontStyle: 'italic',
		color: '#767575',
		height: 10,
		marginBottom: 5,
	},
});

const ClusterOverview = (data: any) => {
	const stats = useMemo(() => {
		return data?.data?.stats;
	}, [data]);

	return (
		<View style={styles.main}>
			<Text style={styles.mainTitle}>Memory & CPU</Text>
			<View style={styles.container}>
				<View style={styles.leftColumn}>
					<View style={styles.heading}>
						<View style={styles.filled}></View>
						<Text style={styles.title}>Used</Text>
					</View>
					<View style={styles.heading}>
						<View style={styles.dashed}></View>
						<Text style={styles.title}>Requested</Text>
					</View>
					<View style={styles.heading}>
						<View style={styles.hollow}></View>
						<Text style={styles.title}>Allocatable</Text>
					</View>
				</View>
				<View style={styles.rightColumn}>
					<Text>
						{stats?.total_cpu_used.toFixed(3)}{' '}
						<Text style={styles.unit}>CPU</Text>
					</Text>
					<Text>
						{stats?.total_cpu_request.toFixed(3)}{' '}
						<Text style={styles.unit}>CPU</Text>
					</Text>
					<Text>
						{stats?.total_cpu_allocatable.toFixed(3)}{' '}
						<Text style={styles.unit}>CPU</Text>
					</Text>
				</View>
				<View style={styles.border}></View>
				<View style={styles.rightColumn}>
					<Text>
						{stats?.total_memory_used.toFixed(3)}{' '}
						<Text style={styles.unit}>Memory</Text>
					</Text>
					<Text>
						{stats?.total_memory_request.toFixed(3)}{' '}
						<Text style={styles.unit}>Memory</Text>
					</Text>
					<Text>
						{stats?.total_memory_allocatable.toFixed(3)}{' '}
						<Text style={styles.unit}>Memory</Text>
					</Text>
				</View>
			</View>
		</View>
	);
};

export default ClusterOverview;
