import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { appReducer } from './app/app';
import { discoveryReducer } from './discovery/discovery';
import { cloudsReducer } from './clouds/clouds';
import { connectorReducer } from './connectors/connectors';
import { integrationReducer } from './integrations/integrations';

const persistConfig = {
  key: 'root',
  storage,
};

const appReducers = combineReducers({
  app: appReducer,
  discovery: discoveryReducer,
  clouds: cloudsReducer,
  connectors: connectorReducer,
  integrations: integrationReducer
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'RESET_STATE') {
    state = undefined;
    localStorage.removeItem('persist:root');
  }

  return appReducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;