import { View, StyleSheet, Text } from '@react-pdf/renderer';
import { IReportsData } from 'common/interfaces';
import ProjectTableHeader from './ProjectTableHeader';
import ProjectTableRow from './ProjectTableRow';

const styles = StyleSheet.create({
	tableContainer: {
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
	mainContainer: {
		marginTop: 20,
		marginBottom: 20,
		flexDirection: 'column',
		gap: 10,
	},
	title: {
		color: '#000000',
		fontFamily: 'Inter',
		fontWeight: 700,
		fontSize: 24,
	},
	subtitle: {
		color: '#000000',
		fontFamily: 'Inter',
		fontWeight: 400,
		fontSize: 10,
	},
	summaryTableContainer: {
		backgroundColor: 'rgba(240, 243, 247, 0.4)',
		border: '0.06rem solid #CBD5E0',
		borderBottom: 'none',
		borderRadius: 6,
		flexDirection: 'row',
		marginTop: 10,
	},
});

const ProjectTable = ({ data }: { data: IReportsData }) => {
	return (
		<View style={styles.mainContainer}>
			<View>
				<Text style={styles.title}>Project List</Text>
				<Text style={styles.subtitle}>
					This report will summarize the anticipated cost-saving initiatives and
					optimizations for each resource type.
				</Text>
				<View style={styles.summaryTableContainer}>
					<View style={styles.tableContainer}>
						<ProjectTableHeader />
						<ProjectTableRow items={data.projects} />
					</View>
				</View>
			</View>
		</View>
	);
};

export default ProjectTable;
